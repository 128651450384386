import React, { CSSProperties } from "react"
import ArrowLeft from "../images/svg/arrow-left.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css"

type Props = {
  id?: string
  style?: CSSProperties
  className?: string
  content: any
}

const TextPill: React.FC<Props> = ({
  id = "",
  className = "",
  content,
  style,
}) => {
  const TextPill = content => {
    return (
      <div className="w-full space-y-3 p-4">
        {content?.item?.title?.length > 0 && (
          <h3
            className="letter-spacing-md relative font-gravity-compressed text-4xl uppercase leading-[0.75]"
            dangerouslySetInnerHTML={{ __html: content.item.title }}
          />
        )}
        {content?.item?.description ? (
          <div className="text-base leading-4">{content.item.description}</div>
        ) : null}
      </div>
    )
  }

  return (
    <section
      id={id}
      style={style}
      className={`flex w-full flex-wrap items-center justify-center px-8 sm:px-12 lg:space-x-[48px] ${className}`}
    >
      <h2
        className={`mb-12 w-full text-center font-gravity-compressed text-[120px] uppercase leading-[0.77] md:text-[180px] lg:mb-0 lg:w-auto lg:text-right ${
          content.slides.length > 1 && `lg:mt-[-66px]`
        }`}
        dangerouslySetInnerHTML={{ __html: content.headline }}
      />
      {(content.slides.length > 1 && (
        <div className="relative w-full max-w-[min(550px,calc(100vw-4rem))] sm:max-w-[min(550px,calc(100vw-6rem))] lg:w-1/2">
          <Swiper
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
              disabledClass:
                "cursor-not-allowed !bg-[rgba(233,233,233,0.3)] opacity-50",
            }}
            modules={[Navigation]}
            edgeSwipeDetection="prevent"
            allowTouchMove={false}
            className="relative mx-auto max-w-full rounded-[10px] bg-[rgba(255,255,255,0.5)] p-12 backdrop-blur-lg lg:max-w-screen-lg"
          >
            {content.slides.map(item => (
              <SwiperSlide key={item.title} className="flex w-full flex-wrap">
                <TextPill item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* NAVIGATION */}
          <div className="mt-2 flex flex-row-reverse">
            <div className="next hover:shadow-xs ml-2 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] text-[#FFFFFF] backdrop-blur-lg hover:bg-[rgba(255,255,255,0.75)]">
              <ArrowLeft className="h-[25px] rotate-180 text-white" />
            </div>
            <div className="prev hover:shadow-xs ml-2 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] text-[#FFFFFF] backdrop-blur-lg hover:bg-[rgba(255,255,255,0.75)]">
              <ArrowLeft className="h-[25px] text-white" />
            </div>
          </div>
        </div>
      )) || (
        <div className="relative w-full max-w-[min(550px,calc(100vw-4rem))] sm:max-w-[min(550px,calc(100vw-6rem))] lg:w-1/2">
          <div className="relative mx-auto max-w-full rounded-[10px] bg-[rgba(255,255,255,0.5)] backdrop-blur-lg lg:max-w-screen-lg">
            <div className="flex w-full flex-wrap">
              <TextPill item={content.slides[0]} />
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default TextPill
