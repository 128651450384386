import React from "react"

const MouseScroll: React.FC = () => {
  return (
    <div className="relative h-[40px] w-[26px] overflow-hidden rounded-[15px] border-[2px] border-solid border-white">
      <div className="absolute top-[8px] left-1/2 z-[2] ml-[-2px] h-[4px] w-[4px] animate-mouse-scroll rounded-[4px] bg-white"></div>
      <div
        className="absolute top-[8px] left-1/2 z-[1] ml-[-2px] h-[4px] w-[4px] animate-mouse-scroll rounded-[4px] bg-[#555555]"
        style={{ animationDelay: "80ms" }}
      ></div>
    </div>
  )
}

export default MouseScroll
