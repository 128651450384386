import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Roadmap from "../components/roadmap/index"
import TextHeader from "../components/text-header"
import ThreeJsScrollytelling from "../components/three-js-scrollytelling"
import IndexBackground from "../images/index-background.png"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="OREMOB BASE" />
      <TextHeader showMouseScroll />
      <ThreeJsScrollytelling />
      <img
        src={IndexBackground}
        alt=""
        className="pointer-events-none fixed inset-0 z-[-1] h-full w-full object-cover object-center mix-blend-hard-light"
      />
      <Roadmap />
    </Layout>
  )
}

export default IndexPage
