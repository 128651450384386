import React, { useState } from "react"
import { AccordionTexts, AccordionTextsInternal } from "../types"

type Props = {
  className?: string
  texts: AccordionTexts
  expandFirst?: boolean
}

const Accordion: React.FC<Props> = ({
  texts,
  className,
  expandFirst = true,
}) => {
  const textsWithId: AccordionTextsInternal = texts.map((t, i) => ({
    ...t,
    id: `item-${i}`,
  }))
  const [activeItemIds, setActiveItemIds] = useState<Array<string>>(
    expandFirst ? [textsWithId[0].id] : []
  )

  const onItemChange =
    (id: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault()

      // close accordion if it was clicked again
      if (activeItemIds.some(v => v === id)) {
        setActiveItemIds(prev => [...prev.filter(v => v !== id)])
      } else {
        setActiveItemIds(prev => [...prev, id])
      }
    }

  const isActiveItem = (id: string) => activeItemIds.some(v => v === id)

  return (
    <section className={`flex w-full flex-row space-x-3 ${className}`}>
      <aside className="flex w-full">
        <ul className="flex w-full flex-col space-y-2">
          {textsWithId.map(t => {
            const isActive = isActiveItem(t.id)

            return (
              <li key={t.id} className="flex flex-col">
                <a
                  href="#"
                  className={`flex justify-between uppercase outline-none after:ml-8 after:mt-2 after:flex after:h-[16px] after:h-3 after:w-[16px] after:w-3 after:flex-shrink-0 after:self-start after:rounded-full after:border-2 after:border-white focus-visible:outline-white ${
                    isActive ? "after:bg-white" : ""
                  }`}
                  onClick={onItemChange(t.id)}
                  tabIndex={0}
                >
                  {t.title}
                </a>
                {isActive && (
                  <div key={t.id} className="break-words leading-normal">
                    {t.text}
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </aside>
      <div className="hidden justify-center">
        {textsWithId.map((t, idx) => (
          <div
            key={t.id}
            className={`flex flex-col items-start hyphens-auto ${
              !isActiveItem(t.id, idx) ? "hidden" : ""
            }`}
          >
            {t.text}
          </div>
        ))}
      </div>
    </section>
  )
}

export default Accordion
