import React, { CSSProperties, useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Countdown from "react-countdown"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Accordion from "../components/accordion"
import ArrowLeft from "../images/svg/arrow-left.svg"
import { Swiper, SwiperSlide, SwiperCore } from "swiper/react"
import { Navigation } from "swiper"
import Lock from "../components/mint/lock"
import UTCDate from "../components/UTCDate"
import "swiper/css"

type Props = {
  className?: string
  style?: CSSProperties
}

const Mint: React.FC<Props> = ({ className = "", style }) => {
  const [state, setState] = useState({})
  const [, setSwiperInstance] = useState<SwiperCore>()

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }

  const { site, imagesMintPhases } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            mint {
              locked
              showOutOfPhasesSection
            }
          }
        }
        imagesMintPhases: allFile(
          limit: 3
          filter: { relativeDirectory: { eq: "mint-phases" } }
          sort: { order: ASC, fields: name }
        ) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    `
  )

  const content = {
    phases: [
      {
        id: 1, // has to be unique
        title: "Phase 1 - WL Mint:",
        description: [
          {
            title: "WHO CAN MINT?",
            text: () => (
              <p>
                During phase 1 only those whom hold OGs, Charm Rings, or WL
                spots will be permitted to mint. Each person will have a limited
                capacity to mint, based off of their allocation. *Charm Rings or
                OGs bought post WL Snapshot 24th Oct. will not be able to mint.
              </p>
            ),
          },
          {
            title: "HOW LONG IS THE MINT?",
            text: () => (
              <p>
                The whitelist mint begins at 1pm UTC+0, Oct. 25 and is open for
                12 hours until it exceeds.
              </p>
            ),
          },
        ],
        price: 252,
        currency: "ADA",
        mintHref: "", //"https://pay.nmkr.io/?p=4a9e484ce10e4dae95dd7d20dc0b480e&c=",
        media: {
          format: "IMAGE", // IMAGE || VIDEO
          content: {
            image: (
              <GatsbyImage
                image={
                  imagesMintPhases.nodes[0].childImageSharp.gatsbyImageData
                }
                alt=""
                objectPosition="top"
                className="aspect-[2/1.5] max-h-[100%] sm:aspect-auto"
              />
            ),
            video: {
              mobile: "",
              desktop: "",
            },
          },
        },
        countdown: {
          startTitle: "STARTS IN",
          duringTitle: "ENDS IN",
          endTitle: "WHITELIST EXCEEDED",
          start: new UTCDate(2022, 10, 25, 13, 0, 0),
          // end: new UTCDate(2022, 10, 26, 0, 50, 0),
          end: new UTCDate(2022, 10, 25, 14, 50, 0),
        },
      },
      {
        id: 2, // has to be unique
        title: "Phase 2 - Burner Phone Mint:",
        description: [
          {
            title: "WHO CAN MINT?",
            text: () => (
              <p>
                During phase 2 only those whom hold Burner Phone NFTs will be
                permitted to Mint. Maximum NFTs per transaction: 5.
              </p>
            ),
          },
          {
            title: "HOW LONG IS THE MINT?",
            text: () => (
              <p>
                Burner Phone MOB exclusive WL Mint: Begins 1am UTC+0, Oct. 26
                and is open for 12 hours - contains +/-1500 NFTs and any that
                are not minted during Phase 1.
              </p>
            ),
          },
        ],
        price: 252,
        currency: "ADA",
        mintHref: "", //"https://pay.nmkr.io/?p=4a9e484ce10e4dae95dd7d20dc0b480e&c=",
        media: {
          format: "IMAGE", // IMAGE || VIDEO
          content: {
            image: (
              <GatsbyImage
                image={
                  imagesMintPhases.nodes[1].childImageSharp.gatsbyImageData
                }
                alt=""
                objectPosition="top"
                className="aspect-[2/1.5] max-h-[100%] sm:aspect-auto"
              />
            ),
            video: {
              mobile: "",
              desktop: "",
            },
          },
        },
        countdown: {
          startTitle: "STARTS IN",
          duringTitle: "ENDS IN",
          endTitle: "BPM SOLD OUT",
          start: new UTCDate(2022, 10, 26, 1, 0, 0),
          end: new UTCDate(2022, 10, 26, 1, 1, 0),
        },
      },
      {
        id: 3, // has to be unique
        title: "Phase 3 - Public Mint:",
        description: [
          {
            title: "WHO CAN MINT?",
            text: () => (
              <p>
                Public mint is available to all. Maximum NFTs per transaction:
                5.
              </p>
            ),
          },
          {
            title: "HOW LONG IS THE MINT?",
            text: () => (
              <p>
                Begins 1pm UTC+0, Oct. 26 and will remain open until all NFTs
                are minted out - contains 6500+ NFTs.
              </p>
            ),
          },
        ],
        price: 252,
        currency: "ADA",
        mintHref: "", //https://pay.nmkr.io/?p=59e770b9facb4426bb01dad0b033973b&c=
        media: {
          format: "IMAGE", // IMAGE || VIDEO
          content: {
            image: (
              <GatsbyImage
                image={
                  imagesMintPhases.nodes[2].childImageSharp.gatsbyImageData
                }
                alt=""
                objectPosition="top"
                className="aspect-[2/1.5] max-h-[100%] sm:aspect-auto"
              />
            ),
            video: {
              mobile: "",
              desktop: "",
            },
          },
        },
        countdown: {
          startTitle: "STARTS IN",
          duringTitle: "ENDS IN",
          endTitle: "PUBLIC MINTED ALL",
          start: new UTCDate(2022, 10, 26, 13, 0, 10),
          end: new UTCDate(2022, 10, 26, 13, 1, 0),
        },
      },
    ],
    links: [
      {
        title: "FAQ",
        href: "/about#faq",
        externalLink: false,
      },
      {
        title: "Whitelist Check",
        href: "/whitelist",
        externalLink: false,
      },
      {
        title: "Discord",
        href: "https://discord.com/invite/oreoreore",
        externalLink: true,
      },
      {
        title: "Twitter",
        href: "https://twitter.com/ore_times_3",
        externalLink: true,
      },
    ],
    outOfPhasesLinks: [
      {
        title: "FAQ",
        href: "/about#faq",
        externalLink: false,
      },
      {
        title: "About",
        href: "/about",
        externalLink: false,
      },
      {
        title: "Twitter",
        href: "https://twitter.com/ore_times_3",
        externalLink: true,
      },
      {
        title: "Discord",
        href: "https://discord.com/invite/oreoreore",
        externalLink: true,
      },
    ],
    maxAmountMintAtOnce: 5,
  }

  const [termsChecked, setTermsChecked] = useState(
    Array.from({ length: content.phases.length }, () => false)
  )

  const incrementCount = id => {
    let newCount
    if (state[`${id}-count`] === undefined) {
      newCount = 2
      setState(prevState => {
        return { ...prevState, [`${id}-count`]: newCount }
      })
    } else {
      newCount =
        state[`${id}-count`] + 1 <= content.maxAmountMintAtOnce
          ? state[`${id}-count`] + 1
          : content.maxAmountMintAtOnce
      setState(prevState => {
        return { ...prevState, [`${id}-count`]: newCount }
      })
    }
  }
  const decrementCount = id => {
    let newCount
    if (state[`${id}-count`] === undefined) {
      newCount = 1
      setState(prevState => {
        return { ...prevState, [`${id}-count`]: newCount }
      })
    } else {
      newCount = state[`${id}-count`] - 1 > 0 ? state[`${id}-count`] - 1 : 1
      setState(prevState => {
        return { ...prevState, [`${id}-count`]: newCount }
      })
    }
  }

  const handleMountCountdown = id => {
    if (state[id] === undefined) {
      setState(prevState => ({ ...prevState, [id]: "BEFORE" }))
    }
  }

  const handleStartCountdown = (e, type, id) => {
    const { completed } = e

    if (!completed) {
      return
    }

    handleCompleteCountdown(type, id)
  }

  const handleCompleteCountdown = (type, id) => {
    switch (type) {
      case "start":
        // set state "DURING" phase - the countdown has completed and the phase is active
        setState(prevState => ({ ...prevState, [id]: "DURING" }))
        break

      case "end":
        setState(prevState => ({ ...prevState, [id]: "ENDED" }))
        break
    }
  }

  // setup logic for countdown renderer and state handling
  // we have 3 states for each phase: "BEFORE", "DURING" and "ENDED"
  const renderCountdown = (e, type, id) => {
    const { days, hours, minutes, seconds, completed } = e

    switch (type) {
      case "start":
        if (!completed) {
          return (
            <p className="font-gravity-compressed text-4xl">
              {days}d {hours}h {minutes}m {seconds}s
            </p>
          )
        } else {
          return ""
        }

      case "end":
        if (!completed) {
          if (state[id] === undefined || state[id] === "BEFORE") {
            return ""
          } else {
            // now we are in phase "DURING"
            return (
              <p className="font-gravity-compressed text-4xl">
                {days}d {hours}h {minutes}m {seconds}s
              </p>
            )
          }
        } else {
          return ""
        }
    }
  }

  // check for current phase through the end date of a phase
  const checkForCurrentPhase = () => {
    let counter = 0

    if (site.siteMetadata.mint.locked) {
      return counter
    }

    content.phases.map(item => {
      if (item.countdown.end) {
        const now = new Date()
        const timeDiff = now.getTime() - item.countdown.end.date.getTime()
        if (timeDiff > 0) {
          counter += 1
        }
      }
    })

    return counter
  }

  const checkBox = index => () => {
    setTermsChecked(prev => {
      prev[index] = !prev[index]
      return [...prev]
    })
  }

  return (
    <section
      id="mint"
      className={`flex w-[100vw] items-center justify-center sm:min-h-[100vh] ${className}`}
      style={style}
    >
      <div className="relative max-w-full p-4 lg:p-12">
        {site.siteMetadata.mint.locked && <Lock />}
        {site.siteMetadata.mint.showOutOfPhasesSection ? (
          <div className="mx-auto rounded-[10px] bg-[rgba(255,255,255,0.5)] backdrop-blur-lg lg:max-w-screen-lg">
            <div className="flex max-h-[80vh] flex-wrap overflow-auto">
              <div className="w-full px-3 pt-3 sm:px-6 sm:pt-6 lg:w-1/2 lg:py-6 lg:pr-0">
                <StaticImage
                  src="../images/sold-out.png"
                  alt=""
                  objectPosition="top"
                  className="aspect-[2/1.5] max-h-[100%] w-full sm:aspect-auto"
                />
              </div>

              <div className="relative flex w-full flex-col gap-4 p-3 sm:p-6 lg:w-1/2">
                <h2 className="letter-spacing-md truncate font-gravity-compressed text-[120px] uppercase leading-[0.75em] sm:text-[160px] xl:text-[170px]">
                  Sold Out
                </h2>
                <div className="flex flex-col gap-4">
                  <p>
                    Thank you from the bottom of our hearts for supporting this
                    project and selling it out in less then a day. We still
                    can&apos;t believe it - this is on another level! The MOB is
                    out here where it belongs.
                  </p>
                  <p>Let&apos;s grow together, let&apos;s thrive together.</p>
                </div>
                <div className="border-b border-t border-t-red border-b-red py-4">
                  <button className="flex-column flex w-full items-center justify-center rounded-full bg-red p-4 text-center font-gravity-compressed text-[24px] uppercase leading-[0.75em] tracking-wide text-white">
                    <a
                      href="https://www.jpg.store/collection/oremob"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Now on JPG Store
                    </a>
                  </button>
                </div>
                {content.outOfPhasesLinks && (
                  <ol className="flex flex-wrap gap-6">
                    {content.outOfPhasesLinks.map(link => (
                      <li key={link.title}>
                        {(link.externalLink && (
                          <a
                            className="relative flex items-center p-1 text-sm font-black text-white before:mr-1 before:block before:h-[16px] before:w-[16px] before:rounded-full before:border-2 before:border-red before:transition hover:!text-red hover:before:bg-red"
                            href={link.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>{link.title}</span>
                          </a>
                        )) || (
                          <AnchorLink
                            stripHash
                            to={link.href}
                            className="relative flex items-center p-1 text-sm font-black text-white before:mr-1 before:block before:h-[16px] before:w-[16px] before:rounded-full before:border-2 before:border-red before:transition hover:!text-red hover:before:bg-red"
                          >
                            <span>{link.title}</span>
                          </AnchorLink>
                        )}
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <Swiper
              onSwiper={setSwiperInstance}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
                disabledClass:
                  "cursor-not-allowed !bg-[rgba(233,233,233,0.3)] opacity-50",
              }}
              modules={[Navigation]}
              initialSlide={checkForCurrentPhase()}
              edgeSwipeDetection="prevent"
              allowTouchMove={false}
              className={`mx-auto rounded-[10px] bg-[rgba(255,255,255,0.5)] backdrop-blur-lg lg:max-w-screen-lg ${
                site.siteMetadata.mint.locked ? "pointer-events-none" : ""
              }`}
            >
              {content.phases.map((item, index) => (
                <SwiperSlide
                  key={item.title}
                  className="flex max-h-[80vh] flex-wrap overflow-auto"
                >
                  <div className="w-full px-3 pt-3 sm:px-6 sm:pt-6 lg:w-1/2 lg:py-6 lg:pr-0">
                    {item.media.format === "IMAGE" && item.media.content.image}
                  </div>
                  <div className="relative w-full p-3 sm:p-6 lg:w-1/2">
                    {item?.title?.length > 0 && (
                      <h2
                        className="letter-spacing-md relative font-gravity-compressed text-4xl uppercase"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    )}
                    {item?.description?.length > 0 && (
                      <Accordion
                        expandFirst={false}
                        className="mt-0 mb-4 self-center border-b border-red py-4 text-base"
                        texts={item.description.map(({ text, title }) => ({
                          title,
                          text: text(item.countdown.start, item.countdown.end),
                        }))}
                      />
                    )}

                    {/* PRICE / COUNTDOWN */}
                    <div className="mb-4 flex border-b border-red pb-4">
                      {item.price > 0 && (
                        <div className="w-full lg:w-1/2">
                          <p className="text-xs uppercase">Mint Price</p>

                          <p className="font-gravity-compressed text-4xl">
                            {`${
                              parseInt(state[`${item.id}-count`] || 1) *
                              item.price
                            }`}
                            &nbsp;{item.currency}
                          </p>
                        </div>
                      )}

                      <div className="w-full lg:w-1/2">
                        {item.countdown.start && (
                          <>
                            {item.countdown.startTitle &&
                              state[`${item.id}`] !== undefined &&
                              state[`${item.id}`] === "BEFORE" && (
                                <p
                                  className="text-xs"
                                  dangerouslySetInnerHTML={{
                                    __html: item.countdown.startTitle,
                                  }}
                                />
                              )}
                            <Countdown
                              key={`countdown-start-${item.id}`}
                              className="font-gravity-compressed text-4xl"
                              date={item.countdown.start.date}
                              onMount={() => handleMountCountdown(item.id)}
                              onStart={e =>
                                handleStartCountdown(e, "start", item.id)
                              }
                              onComplete={() =>
                                handleCompleteCountdown("start", item.id)
                              }
                              renderer={e =>
                                renderCountdown(e, "start", `${item.id}`)
                              }
                            />
                          </>
                        )}
                        {item.countdown.end && (
                          <>
                            {item.countdown.duringTitle &&
                              state[`${item.id}`] !== undefined &&
                              state[`${item.id}`] === "DURING" && (
                                <p
                                  className="text-xs"
                                  dangerouslySetInnerHTML={{
                                    __html: item.countdown.duringTitle,
                                  }}
                                />
                              )}
                            {item.countdown.endTitle &&
                              state[`${item.id}`] !== undefined &&
                              state[`${item.id}`] === "ENDED" && (
                                <>
                                  <p className="text-xs uppercase">Sold out</p>
                                  <p
                                    className="font-gravity-compressed text-4xl"
                                    dangerouslySetInnerHTML={{
                                      __html: item.countdown.endTitle,
                                    }}
                                  />
                                </>
                              )}
                            <Countdown
                              key={`countdown-end-${item.id}`}
                              className="font-gravity-compressed text-4xl"
                              date={item.countdown.end.date}
                              onMount={() => handleMountCountdown(item.id)}
                              onStart={e =>
                                handleStartCountdown(e, "end", item.id)
                              }
                              onComplete={() =>
                                handleCompleteCountdown("end", item.id)
                              }
                              renderer={e =>
                                renderCountdown(e, "end", `${item.id}`)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    {state[`${item.id}`] !== undefined &&
                      state[`${item.id}`] === "DURING" && (
                        <>
                          <div className="mb-4 flex items-start border-b border-red pb-4">
                            <input
                              id="link-checkbox"
                              type="checkbox"
                              value={termsChecked[index]}
                              onChange={checkBox(index)}
                              className="mt-[4px] h-[18px] w-[18px] cursor-pointer rounded border-red bg-[rgba(0,0,0,0.5)] focus:ring-2 focus:ring-blue-500"
                            />
                            <label
                              htmlFor="link-checkbox"
                              className="ml-2 text-base font-medium text-[#FFFFFF] dark:text-gray-300"
                            >
                              I agree to the{" "}
                              <AnchorLink
                                stripHash
                                to="/legal-notice#toc"
                                className="underline"
                              >
                                Terms and Conditions
                              </AnchorLink>{" "}
                              and take note of the{" "}
                              <AnchorLink
                                stripHash
                                to="/legal-notice#toc"
                                className="underline"
                              >
                                information on the right of withdrawal
                              </AnchorLink>{" "}
                              of OREMOB UG.
                            </label>
                          </div>
                          <div className="mb-4 border-b border-red pb-4">
                            <div
                              className={classNames(
                                "flex flex-wrap font-gravity-wide",
                                !termsChecked[index] &&
                                  "pointer-events-none cursor-not-allowed opacity-50"
                              )}
                            >
                              <div className="flex w-full items-center justify-center space-x-2 pb-4 lg:w-1/2 lg:pb-0 lg:pr-4">
                                <button
                                  className="h-[56px] w-[56px] flex-none rounded-full bg-red text-white"
                                  onClick={() => decrementCount(item.id)}
                                >
                                  -
                                </button>
                                <div className="flex h-[56px] w-full grow items-center justify-center rounded-full bg-white text-black">
                                  <span>
                                    {state[`${item.id}-count`] !== undefined
                                      ? state[`${item.id}-count`]
                                      : 1}
                                  </span>
                                </div>
                                <button
                                  className="h-[56px] w-[56px] flex-none rounded-full bg-red text-white"
                                  onClick={() => incrementCount(item.id)}
                                >
                                  +
                                </button>
                              </div>
                              <button
                                className={classNames(
                                  !termsChecked[index] &&
                                    "cursor-not-allowed opacity-50",
                                  "flex-column flex w-full items-center justify-center rounded-full bg-red p-4 text-center uppercase text-white lg:w-1/2"
                                )}
                              >
                                {(termsChecked[index] && (
                                  <a
                                    href={`${item.mintHref}${
                                      state[`${item.id}-count`] || 1
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Mint and Pay Now
                                  </a>
                                )) || <span>Mint and Pay Now</span>}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    {content.links && (
                      <ol className="flex flex-wrap justify-between">
                        {content.links.map(link => (
                          <li key={link.title}>
                            {(link.externalLink && (
                              <a
                                className="relative flex items-center p-1 text-black before:mr-1 before:block before:h-[16px] before:w-[16px] before:rounded-full before:border-2 before:border-red before:transition hover:!text-red hover:before:bg-red"
                                href={link.href}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>{link.title}</span>
                              </a>
                            )) || (
                              <AnchorLink
                                stripHash
                                to={link.href}
                                className="relative flex items-center p-1 text-black before:mr-1 before:block before:h-[16px] before:w-[16px] before:rounded-full before:border-2 before:border-red before:transition hover:!text-red hover:before:bg-red"
                              >
                                <span>{link.title}</span>
                              </AnchorLink>
                            )}
                          </li>
                        ))}
                      </ol>
                    )}

                    <div className="mt-6 flex items-center uppercase lg:absolute lg:bottom-[19px] lg:mt-0">
                      <span className="p-0">
                        {`Phase ${item.id} of ${content.phases.length}`}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* NAVIGATION */}
            <div className="mt-2 flex flex-row-reverse">
              <div className="next hover:shadow-xs ml-2 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] text-[#FFFFFF] backdrop-blur-lg hover:bg-[rgba(255,255,255,0.75)]">
                <ArrowLeft className="h-[25px] rotate-180 text-white" />
              </div>
              <div className="prev hover:shadow-xs ml-2 flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[10px] bg-[rgba(255,255,255,0.5)] text-[#FFFFFF] backdrop-blur-lg hover:bg-[rgba(255,255,255,0.75)]">
                <ArrowLeft className="h-[25px] text-white" />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Mint
