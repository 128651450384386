import React, { CSSProperties } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

type Props = {
  className?: string
  style?: CSSProperties
}

const Trigger: React.FC<Props> = ({ className = "", style }) => {
  return (
    <section
      className={`flex w-[100vw] flex-col items-center justify-center sm:min-h-[100vh] lg:gap-4 ${className}`}
      style={style}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        <h2 className="mb-12 w-full text-center font-gravity-compressed text-[120px] uppercase leading-[0.77] md:text-[180px] lg:mb-0 lg:w-auto lg:text-right">
          Sabukaru
          <br />x<br />
          OREMOB
        </h2>
        <div className="mx-auto max-w-[min(550px,calc(100vw-4rem))] rounded-t-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:p-0 lg:backdrop-blur-0">
          <StaticImage
            src="../../images/shop/trigger.jpg"
            alt=""
            objectPosition="center"
            objectFit="contain"
            className="w-full lg:w-[480px]"
          />
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[min(550px,calc(100vw-4rem))] justify-center rounded-b-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:backdrop-blur-0">
        <div>
          <button className="flex-column flex w-full items-center justify-center rounded-full bg-red py-4 px-8 text-center font-gravity-compressed text-[24px] uppercase leading-[0.75em] tracking-wide text-white">
            <AnchorLink stripHash to="/shop">
              SOLD OUT
            </AnchorLink>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Trigger
