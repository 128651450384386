import React, { useEffect, useRef } from "react"
import TextPill from "./text-pill"
import Mint from "../components/mint"
import ShopTrigger from "../components/shop/trigger"
import DonateTrigger from "../components/nonprofit-cycle/DonateTrigger"
import BurnTrigger from "../components/casey-burn/burnTrigger"
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  sRGBEncoding,
  Color,
  DefaultLoadingManager,
  Mesh,
  ACESFilmicToneMapping,
  PMREMGenerator,
  LoadingManager,
  Fog,
  EquirectangularReflectionMapping,
} from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import "../components/scroll-controls/scroller.css"
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js"
import loadingAnimation from "../images/loading.gif"
import BoneDragonTrigger from "./bone-dragon/BoneDragonTrigger"
import CeremonyTrigger from "./casey-ceremony/CeremonyTrigger"
import RiddleTrigger from "./riddle/riddleTrigger"
import ShirtDropTrigger from "./shirt-drop/shirtDropTrigger"
import YamepiTrigger from "./yamepi/yamepiTrigger"
import tiamondsTrigger from "./tiamonds/tiamondsTrigger"
import ShirtDrop2Trigger from "./shirt-drop/drop-2/shirtDrop2Trigger"
import shirtDrop3Trigger from "./shirt-drop/drop-3/shirtDrop3Trigger"

const customTriggers = [
  shirtDrop3Trigger,
  ShirtDrop2Trigger,
  tiamondsTrigger,
  YamepiTrigger,
  ShirtDropTrigger,
  RiddleTrigger,
  BurnTrigger,
  CeremonyTrigger,
  BoneDragonTrigger,
  DonateTrigger,
  ShopTrigger,
]

const content = {
  textPills: [
    {
      headline: "OREMOB <br/>10K PFP",
      slides: [
        {
          title: "HAND-DRAWN AND HAND SELECTED BY THE ARTIST",
          description: (
            <>
              10.000 avatars born from more than 500+ hand drawn traits will be
              released to the NFT Community on Cardano. The sale will be split
              between the Whitelist, Burnerphone MOB and Public Sale. All
              avatars, PFPs were manually selected and refined with great care.
              The assets are all hand-drawn using ink on paper, incorporating
              Anime, Manga, Pop-Culture, and Ukiyo-e. Every piece is
              hand-picked.
            </>
          ),
        },
      ],
    },
  ],
}

const ThreeJsScrollytelling: React.FC = () => {
  const scrollLength = 2200 // in vh, adjust this to have more or less height

  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const loadingContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (typeof document !== "undefined") {
      const params = {
        toneMappingExposure: 1.0,
        color: {
          bg: 0xff0000,
        },
        fog: {
          active: true,
          near: 0,
          far: 280,
        },
      }

      const {
        CameraRig,
        ScrollControls,
        ThreeDOFControls,
        ScrollAdaptor,
        // eslint-disable-next-line @typescript-eslint/no-var-requires
      } = require("three-story-controls")

      // Set up the three.js scene
      const scene = new Scene()
      const canvasContainer = canvasContainerRef.current
      const loadingContainer = loadingContainerRef.current

      if (!canvasContainer || !loadingContainer) {
        throw new Error("Canvas and/or loading container missing")
      }

      // fog
      if (params.fog.active) {
        const near = params.fog.near
        const far = params.fog.far
        scene.fog = new Fog(params.color.bg, near, far)
      }
      scene.background = new Color(params.color.bg)

      const camera = new PerspectiveCamera(
        16,
        canvasContainer.offsetWidth / canvasContainer.offsetHeight,
        0.1,
        500
      )

      let exrCubeRenderTarget
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let exrBackground
      let newEnvMap

      let object, pfps

      // manager
      const loadModel = () => {
        DefaultLoadingManager.onLoad = () => {
          pmremGenerator.dispose()

          canvasContainer.classList.add("[&>canvas]:opacity-100")
          canvasContainer.classList.remove("[&>canvas]:opacity-0")
          loadingContainer.classList.remove("opacity-100", "animate-pulse")
          loadingContainer.classList.add("opacity-0", "animate-none")
        }

        const pmremGenerator = new PMREMGenerator(renderer)
        pmremGenerator.compileEquirectangularShader()

        const meshes = []

        new EXRLoader().load(
          "/three-d-files/fish_hoek_beach_1k.exr",
          texture => {
            texture.mapping = EquirectangularReflectionMapping
            exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture)
            exrBackground = exrCubeRenderTarget.texture
            newEnvMap = exrCubeRenderTarget ? exrCubeRenderTarget.texture : null

            scene.environment = newEnvMap
            texture.dispose()

            // TRAVERSE OBJECT

            object.traverse(child => {
              //This allow us to check if the children is an instance of the Mesh constructor
              if (child instanceof Mesh) {
                meshes.push(child)

                child.material.emissiveIntensity = 2
                child.castShadow = true
                child.receiveShadow = true
              }
            })

            scene.add(object)
            scene.add(pfps)
          }
        )
      }

      const manager = new LoadingManager(loadModel)

      // Initialize the CameraRig
      const rig = new CameraRig(camera, scene)
      let scrollControls, threeDOFControls

      // Initialize the controls
      const initializeControls = camera => {
        // Give the rig the animation clip along with the translation and rotation object names
        rig.setAnimationClip(camera.animations[0])
        rig.setAnimationTime(0)

        // Set up ScrollControls and specify the timing for the transition animations
        // See https://nytimes.github.io/three-story-controls/docs/three-story-controls.scrollcontrols.html for more
        scrollControls = new ScrollControls(rig, {
          scrollElement: document.querySelector(".scroller"),
          dampingFactor: 0.5,
          endOffset: "-50vh",
        })
        const scrollAdaptor = new ScrollAdaptor({
          scrollElement: document.querySelector(".scroller"),
          dampingFactor: 0.5,
        })
        scrollAdaptor.connect()

        // Set up ThreeDOFControls
        // See https://nytimes.github.io/three-story-controls/docs/three-story-controls.threedofcontrols.html for more
        threeDOFControls = new ThreeDOFControls(rig, {
          panFactor: Math.PI / 100,
          tiltFactor: Math.PI / 20,
          truckFactor: 0,
          pedestalFactor: 0,
        })

        // Enable controls
        scrollControls?.enable()
        threeDOFControls?.enable()
      }

      // Initialize loaders
      const loader = new GLTFLoader(manager)
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath(
        "https://unpkg.com/three@0.148.0/examples/jsm/libs/draco/"
      )
      loader.setDRACOLoader(dracoLoader)

      // Wait for both assets to load to hide the loading text and initialize controls
      Promise.all([
        loader.loadAsync("/three-d-files/220831_Main Page_OneObject.glb"),
        loader.loadAsync("/three-d-files/220831_Main Page_PFPs.glb"),
        loader.loadAsync("/three-d-files/camera.glb"),
      ])
        .then(assets => {
          const [mainModel, pfpModel, camera] = assets
          object = mainModel.scene
          pfps = pfpModel.scene

          initializeControls(camera)
        })
        .catch(e => console.log(e))

      const renderer = new WebGLRenderer({ alpha: true })
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.toneMapping = ACESFilmicToneMapping
      renderer.outputEncoding = sRGBEncoding
      renderer.toneMappingExposure = params.toneMappingExposure
      canvasContainer.appendChild(renderer.domElement)

      const onWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()

        renderer.setSize(window.innerWidth, window.innerHeight)
      }

      window.addEventListener("resize", onWindowResize, false)

      const render = t => {
        if (newEnvMap) {
          scene.environment = newEnvMap
        }

        if (rig.hasAnimation) {
          scrollControls?.update(t)
          threeDOFControls?.update(t)
        }

        renderer.render(scene, camera)
        window.requestAnimationFrame(render)
      }

      render()
    }

    return () => {
      // KILL ALL
    }
  }, [])

  const customTriggerRatio = 3/4; // we want it to be 75% of the full height
  const textPillsRatio = (1 - customTriggerRatio) / 2; // we want this to be 1/2 of the remainer
  const otherContentRatio = 1 - textPillsRatio - customTriggerRatio; // we want this to be the rest

  return (
    <div className="scene w-full">
      <div
        ref={canvasContainerRef}
        className="canvas-parent relative sticky max-w-[100vw] overflow-x-hidden [&>canvas]:opacity-0 [&>canvas]:transition-opacity [&>canvas]:duration-[1000ms] [&>canvas]:ease-in-out"
      >
        <div
          className="absolute inset-0 h-full w-full opacity-100 transition-opacity duration-[1000ms] ease-in-out"
          ref={loadingContainerRef}
        >
          <div className="absolute inset-0 top-[50px] h-full w-full animate-pulse bg-scrollytelling bg-top bg-no-repeat opacity-40 md:bg-scrollytelling-md lg:bg-scrollytelling-lg portrait:bg-cover landscape:bg-contain" />
          <div className="absolute left-1/2 top-[120px] flex h-screen -translate-x-1/2 flex-col space-y-2">
            <img
              src={loadingAnimation}
              alt="Loading..."
              className="w-[200px] lg:w-[250px]"
            />
            <div className="text-center font-gravity-wide text-[10px] uppercase after:inline-block after:w-[1.5em] after:animate-dotty after:text-left after:content-['']">
              Loading
            </div>
          </div>
        </div>
      </div>
      <div
        className="scroller relative z-[2] grid w-[100vw]"
        style={{ height: `${scrollLength}vh` }}
      >
        {customTriggers.map((Trigger, index) => (
          <div
            key={`trigger_${index}`}
            style={{
              height: `${(scrollLength * customTriggerRatio) / customTriggers.length}vh`,
              marginTop: `${
                (scrollLength * customTriggerRatio) * (index / customTriggers.length)
              }vh`,
            }}
            className="relative col-[1] row-[1] pointer-events-all"
          >
            <Trigger className="sticky top-0 mt-[50%] h-screen md:mt-[calc(50%_-_50vh)]" />
          </div>
        ))}

        {content.textPills.map((item, i) => (
          <div
            key={`text_pill_${i}`}
            style={{
              height: `${
                (scrollLength * textPillsRatio) / (content.textPills.length + 1)
              }vh`,
              marginTop: `${
                (scrollLength * customTriggerRatio) +
                ((scrollLength * textPillsRatio) / (content.textPills.length + 1)) * i
              }vh`,
            }}
            className="relative col-[1] row-[1]"
          >
            <TextPill
              className="caption sticky top-0 mt-[50%] h-screen content-center md:mt-[calc(50%_-_50vh)]"
              content={item}
            />
          </div>
        ))}

        <div
          style={{
            height: `${scrollLength * otherContentRatio}vh`,
            marginTop: `${scrollLength - (scrollLength * otherContentRatio)}vh`,
          }}
          className="relative col-[1] row-[1]"
        >
          <Mint className="sticky top-0 mt-[50%] h-screen md:mt-[calc(50%_-_50vh)]" />
        </div>
      </div>
    </div>
  )
}

export default ThreeJsScrollytelling
