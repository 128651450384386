import React, { useLayoutEffect, useRef } from "react"
import ReactTooltip from "react-tooltip"
import imagesLoaded from "imagesloaded"
import TweenMax from "./js/TweenMax.min"
import Draggabilly from "./js/draggabilly.pkgd.min"
import "./css/base.css"

const stripItems = [
  {
    headline: "Values & ethics",
    desc: "Over the decades, we have built up a significant amount of experience in projecting values and ensuring our foundations are solid. Therefore, our value chain expands from the inside out. We begin with what we can achieve ourselves within our internal team, and follow with what we hope to achieve from leading by example.",
    imgSrc: "/roadmap/01.jpg",
    completed: true,
  },
  {
    headline: "OREMOB Community",
    desc: "Community is the essence of everything, and incepting trust as a core foundation within our community is where we want to start our journey. Your actions within the Ore community will determine your role and we are so excited to see how we grow together.",
    imgSrc: "/roadmap/02.jpg",
    completed: true,
  },
  {
    headline: "ORE OGS 1/1 CNFT",
    desc: "The foundation of our story was laid in 2021 where 252 of  our 1/1 artworks were brought to Cardano and are currently still the most sought-after works in the CNFT space. Their quality and scarcity qualify them as “grails” for many CNFT collectors. These limited edition drops were critical to our organic growth strategy as it allowed us to develop a core community who values high quality art. All future OG drops will be exclusive to holders who own at least one of ORE’s pieces.",
    imgSrc: "/roadmap/03.jpg",
    completed: true,
  },
  {
    headline: "BURNER PHONE MOB",
    desc: "Lovingly called the ‘Burner Phone Mob’ by OG collectors, this MOB was established to cut through some of the noise that is typical in any new Discord channel. They are a close-knit community who protect their BURNER PHONE CNFTs in their wallets and pledging to never cross the MOB. Many decisions are made with this group as representatives of the larger community, and their tight circle is cherished throughout the entire community for its dedication and everlasting loyalty. A place in the Burner-phone MOB is earned, and not bought.",
    imgSrc: "/roadmap/04.jpg",
    completed: true,
  },
  {
    headline: "PFP OREMOB CNFTs",
    desc: "The new era that we will embark on is one where you decide on your identity. Digital Avatars are roles you commit to in a setting that needs creative and courageous care. ORE has created over 500+ unique traits, all hand drawn with ink on paper and colored digitally. There are 13 different factions within the OREMOB that will reveal a lot of depth towards the ever growing lore of the OREMOB. The mint price at 252 ADA with royalties of 6% are the starting point for this unique project.",
    imgSrc: "/roadmap/05.jpg",
    completed: true,
  },
  {
    headline: "FILTER & GALLERY",
    desc: "You won’t have to go through the metadata yourself anymore! Discovering the traits of each artwork without having to connect too many dots yourself is the next step in our journey. Whether you’re looking at ORE ORE ORE OGs or OREMOB PFPs, you will have a bird's eye view of everything in play.",
    imgSrc: "/roadmap/06.jpg",
    completed: true,
  },
  {
    headline: "MANGA & ANIME SHORTS",
    desc: "A portion of the funds raised from further ORE OG drops will be invested in strengthening the lore and supporting the visual storytelling of our 13 factions. We intend to utilize community input as well as professional storytellers to build a story that the Mob can collectively be proud of.\n",
    imgSrc: "/roadmap/07.jpg",
    completed: false,
  },
  {
    headline: "MUSIC",
    desc: "Music has always been a large part of ORE’s life and creative process. Our goal is to leverage our platform to introduce up-and-coming musicians to the Cardano ecosystem. Part of the revenue from music streams will flow into one of our talent adept programs, with more info coming soon!",
    imgSrc: "/roadmap/08.jpg",
    completed: false,
  },
  {
    headline: "MORE ORE",
    desc: "The NFT space is the newest and most innovative way for people to interact with the brands and content creators they love. Building off of the positive feedback we received from the BP Mob scavenger hunt, we will deliver further community-exclusive events that offer more insight to ORE’s mind.",
    imgSrc: "/roadmap/09.jpg",
    completed: false,
  },
]

const Roadmap: React.FC = () => {
  const mount = useRef(null)

  useLayoutEffect(() => {
    // Preload all the images in the page
    imagesLoaded(
      document.querySelectorAll(".img-inner"),
      { background: true },
      () => document.body.classList.remove("loading")
    )

    // const body = document.body;

    const MathUtils = {
      lineEq: (y2, y1, x2, x1, currentVal) => {
        // y = mx + b
        const m = (y2 - y1) / (x2 - x1),
          b = y1 - m * x1
        return m * currentVal + b
      },
      lerp: (a, b, n) => (1 - n) * a + n * b,
      getRandomFloat: (min, max) =>
        (Math.random() * (max - min) + min).toFixed(2),
    }

    const getMousePos = e => {
      let posx = 0
      let posy = 0
      if (!e) e = (typeof window !== "undefined" && window.event) || null
      if (e.pageX || e.pageY) {
        posx = e.pageX
        posy = e.pageY
      } else if (e.clientX || e.clientY) {
        posx =
          e.clientX +
          mount.current.scrollLeft +
          document.documentElement.scrollLeft
        posy =
          e.clientY +
          mount.current.scrollTop +
          document.documentElement.scrollTop
      }
      return { x: posx, y: posy }
    }

    const wWidth = (typeof window !== "undefined" && window.innerWidth) || 0
    const wHeight = (typeof window !== "undefined" && window.innerHeight) || 0
    let winsize
    const calcWinsize = () => (winsize = { width: wWidth, height: wHeight })
    calcWinsize()
    typeof window !== "undefined" &&
      window.addEventListener("resize", calcWinsize)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let mousepos = { x: winsize.width / 2, y: winsize.height / 2 }
    typeof window !== "undefined" &&
      window.addEventListener("mousemove", ev => (mousepos = getMousePos(ev)))

    // Strip Item
    class StripItem {
      constructor(el) {
        this.DOM = { el: el }
        this.DOM.image = this.DOM.el.querySelector(".img-inner")
        // this.DOM.number = this.DOM.el.querySelector('.strip__item-link');
      }
    }

    // Content Item
    class ContentItem {
      constructor(el) {
        this.DOM = { el: el }
        this.DOM.image = this.DOM.el.querySelector(".img-outer")
        this.DOM.title = this.DOM.el.querySelector(".content__item-title")
        this.DOM.text = this.DOM.el.querySelector(".content__item-text")
      }
    }

    // Images strip
    class Strip {
      constructor(el) {
        this.DOM = { el: el }
        this.DOM.strip = this.DOM.el.querySelector(".strip")
        this.items = []
        ;[...this.DOM.strip.querySelectorAll(".strip__item")].forEach(item =>
          this.items.push(new StripItem(item))
        )
        // The draggable container
        this.DOM.draggable = this.DOM.el.querySelector(".draggable")

        // The width of the draggable container (also the strip container)
        this.draggableWidth = this.DOM.draggable.offsetWidth
        // The total amount that we can drag the draggable container, so that both the first and last image stay next to the viewport boundary (left and right respectively)
        this.maxDrag =
          this.draggableWidth < winsize.width
            ? 0
            : this.draggableWidth - winsize.width
        // The current amount (in pixels) that was dragged
        this.dragPosition = 0
        // Initialize the
        if (typeof window !== "undefined") {
          this.draggie = new Draggabilly(this.DOM.draggable, { axis: "x" })
        }

        this.init()
        this.initEvents()
      }
      init() {
        this.renderedStyles = {
          position: { previous: 0, current: this.dragPosition },
          scale: { previous: 1, current: 1 },
          imgScale: { previous: 1, current: 1 },
          opacity: { previous: 1, current: 1 },
          coverScale: { previous: 0.75, current: 0.75 },
          coverOpacity: { previous: 0, current: 0 },
          indicatorScale: { previous: 1, current: 1 },
        }

        this.render = () => {
          this.renderId = undefined

          for (const key in this.renderedStyles) {
            this.renderedStyles[key].previous = MathUtils.lerp(
              this.renderedStyles[key].previous,
              this.renderedStyles[key].current,
              0.1
            )
          }

          TweenMax.set(this.DOM.strip, {
            x: this.renderedStyles.position.previous,
          })

          for (const item of this.items) {
            TweenMax.set(item.DOM.el, {
              scale: this.renderedStyles.scale.previous,
              opacity: this.renderedStyles.opacity.previous,
            })
            TweenMax.set(item.DOM.image, {
              scale: this.renderedStyles.imgScale.previous,
            })
          }

          if (!this.renderId) {
            this.renderId = requestAnimationFrame(() => this.render())
          }
        }
        this.renderId = requestAnimationFrame(() => this.render())
      }
      initEvents() {
        this.onDragStart = () => {
          this.renderedStyles.scale.current = 0.8
          this.renderedStyles.imgScale.current = 1.6
          this.renderedStyles.opacity.current = 0.3
          this.renderedStyles.coverScale.current = 1
          this.renderedStyles.coverOpacity.current = 1
          this.renderedStyles.indicatorScale.current = 0
        }

        this.onDragMove = event => {
          // The possible range for the drag is draggie.position.x = [-maxDrag,0 ]
          if (this.draggie.position.x >= 0) {
            // the max we will be able to drag is winsize.width/2
            this.dragPosition = MathUtils.lineEq(
              0.5 * winsize.width,
              0,
              winsize.width,
              0,
              this.draggie.position.x
            )
          } else if (this.draggie.position.x < -1 * this.maxDrag) {
            // the max we will be able to drag is winsize.width/2
            this.dragPosition = MathUtils.lineEq(
              0.5 * winsize.width,
              0,
              this.maxDrag + winsize.width,
              this.maxDrag,
              this.draggie.position.x
            )
          } else {
            this.dragPosition = this.draggie.position.x
          }
          this.renderedStyles.position.current = this.dragPosition

          mousepos = getMousePos(event)
        }

        this.onDragEnd = () => {
          // reset draggable if out of bounds.
          if (this.draggie.position.x > 0) {
            this.dragPosition = 0
            this.draggie.setPosition(this.dragPosition, this.draggie.position.y)
          } else if (this.draggie.position.x < -1 * this.maxDrag) {
            this.dragPosition = -1 * this.maxDrag
            this.draggie.setPosition(this.dragPosition, this.draggie.position.y)
          }
          this.renderedStyles.position.current = this.dragPosition
          this.renderedStyles.scale.current = 1
          this.renderedStyles.imgScale.current = 1
          this.renderedStyles.opacity.current = 1
          this.renderedStyles.coverScale.current = 0.75
          this.renderedStyles.coverOpacity.current = 0
          this.renderedStyles.indicatorScale.current = 1
        }

        this.draggie.on("pointerDown", this.onDragStart)
        this.draggie.on("dragMove", this.onDragMove)
        this.draggie.on("pointerUp", this.onDragEnd)

        if (typeof window !== "undefined") {
          window.addEventListener("resize", () => {
            this.maxDrag =
              this.draggableWidth < winsize.width
                ? 0
                : this.draggableWidth - winsize.width
            if (
              Math.abs(this.dragPosition) + winsize.width >
              this.draggableWidth
            ) {
              const diff =
                Math.abs(this.dragPosition) +
                winsize.width -
                this.draggableWidth
              // reset dragPosition
              this.dragPosition = this.dragPosition + diff
              this.draggie.setPosition(
                this.dragPosition,
                this.draggie.position.y
              )
            }
          })
        }
      }
    }

    // The images strip
    new Strip(document.querySelector(".strip-outer"))

    // The content elements
    const contentItems = []
    ;[...document.querySelectorAll(".content__item")].forEach(item =>
      contentItems.push(new ContentItem(item))
    )

    return () => {
      // KILL ALL
    }
  }, [])

  const overrideTooltipPos = ({ left }: { left: number }) => ({
    left,
    top: 18,
  })

  return (
    <div
      id="roadmap"
      ref={mount}
      className="relative w-full cursor-pointer lg:w-1/3"
    >
      <h2 className="mx-4 mb-4 mt-12 font-gravity-compressed text-[120px] uppercase leading-[1em] lg:text-[180px]">
        Roadmap
      </h2>
      <div className="roadmap-main w-screen">
        <div className="frame">
          <div className="frame__indicator"></div>
        </div>
        <div className="strip-outer">
          <div className="strip-inner">
            <div className="draggable"></div>
            <div className="strip">
              {stripItems.length > 0 &&
                stripItems.map((item, i) => (
                  <div key={item.headline}>
                    <div className="strip__item ml-[20px]">
                      <div className="img-outer img-outer--size-s">
                        <div
                          className="img-inner"
                          style={{ backgroundImage: `url('${item.imgSrc}')` }}
                        ></div>
                      </div>
                      <div className="img-outer--size-s max-w-[calc(var(--imgheight)*0.7)]">
                        <div className="mb-1 flex pr-[10px]">
                          <a
                            className="pointer-events-auto block"
                            data-tip
                            data-for={`roadmap-item-${i}`}
                          >
                            <span className="mr-[5px] flex h-[20px] w-[50px] flex-row items-center justify-center space-x-2 rounded-full bg-white font-sans text-[11px] uppercase leading-none text-black">
                              Info
                            </span>
                          </a>
                          {item.completed && (
                            <a
                              className="pointer-events-auto"
                              data-tip
                              data-for="completed"
                            >
                              <span className="mr-[5px] flex h-[20px] w-[20px] flex-row items-center justify-center space-x-2 rounded-full bg-white font-sans text-[11px] uppercase leading-none text-black">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="8"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                                </svg>
                              </span>
                            </a>
                          )}
                        </div>
                        <div className="h-[70px] pb-[50px] pr-[10px] font-sans">
                          {item.headline}
                        </div>
                      </div>
                    </div>
                    <ReactTooltip
                      id={`roadmap-item-${i}`}
                      className="tooltip !rounded-[16px] !text-black !backdrop-blur"
                      type="info"
                      place="bottom"
                      backgroundColor="rgba(255,255,255,0.5)"
                      arrowColor="rgba(255,255,255,0.5)"
                      overridePosition={overrideTooltipPos}
                    >
                      <div className="w-[250px] max-w-full font-sans text-[12px] !text-black lg:text-[14px]">
                        {item.desc}
                      </div>
                    </ReactTooltip>
                  </div>
                ))}

              <ReactTooltip
                id="completed"
                className="tooltip !rounded-[16px] !text-black !backdrop-blur"
                type="info"
                place="bottom"
                backgroundColor="rgba(255,255,255,0.5)"
                arrowColor="rgba(255,255,255,0.5)"
                overridePosition={overrideTooltipPos}
              >
                <div className="max-w-full font-sans text-[12px] lg:text-[14px]">
                  Completed
                </div>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap
