import React from "react"

const Lock: React.FC = () => {
  return (
    <div className="absolute inset-16 bottom-28 z-10 flex flex-col items-center justify-center gap-0 border-4 border-white bg-red lg:inset-36 lg:bottom-48">
      <div className="font-gravity-compressed text-[60px] uppercase leading-none lg:text-[120px]">
        Update
      </div>
      <div className="px-8 text-center lg:px-16">
        We will announce a mint date once everything is in the box and
        fine-tuned. You can expect this within the next few weeks. We will
        announce the mint one week in advance. See you on discord and twitter!
        The WL is open again and we will be adding moREMOBsters to it.
        <br />
        Team OREMOB 07th Sept. 2022
      </div>
    </div>
  )
}

export default Lock
