import React from "react"
import useScrollPosition from "@react-hook/window-scroll"
import MouseScroll from "./mouse-scroll"

type Props = {
  showMouseScroll?: boolean
}

const TextHeader: React.FC<Props> = ({ showMouseScroll = false }) => {
  const scrollY = useScrollPosition(60)

  return (
    <div className="flex w-full">
      <div className="flex w-full flex-col items-center space-y-4">
        <h1 className="mb-0 whitespace-nowrap text-center font-gravity-compressed text-[39.5vw] leading-[0.7em]">
          OREMOB
        </h1>
        <p className="mx-auto mb-4 w-full px-4 text-center font-sans text-base leading-[1em] lg:max-w-[712px]">
          OREMOB by Berlin artist ORE ORE ORE is the expansion of his successful
          Web3 anime project he started in 2021. A community focused on exciting
          narrative in a world of distinctive visual identities and strong
          pop-culture references as artform.
        </p>
        <p className="mx-auto mb-4 w-full px-4 text-center font-sans text-base leading-[1em] lg:max-w-[712px]">
          The OREMOB&apos;s imminent takeover of the Cardano NFT space is
          starting right here.
        </p>
        {showMouseScroll && (
          <div
            className={`pt-8 transition-opacity duration-300 ease-in-out ${
              scrollY > 150 ? "opacity-0" : "opacity-100"
            }`}
          >
            <MouseScroll />
          </div>
        )}
      </div>
    </div>
  )
}

export default TextHeader
