import React, { CSSProperties } from "react"
import ShirtDrop3Video from "../../../videos/shirt-drop/shirt-drop-3-trigger.webm"

type Props = {
  className?: string
  style?: CSSProperties
}

const shirtDrop3Trigger: React.FC<Props> = ({ className = "", style }) => {
  return (
    <section
      className={`flex w-[100vw] flex-col items-center justify-center sm:min-h-[100vh] lg:gap-4 ${className}`}
      style={style}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        <h2 className="mb-12 w-full text-center font-gravity-compressed text-[120px] uppercase leading-[0.77] md:text-[180px] lg:mb-0 lg:w-auto lg:text-right">
          ADA
          <br />
          MOON
          <br />
          DROP
        </h2>
        <div className="mx-auto max-w-[min(550px,calc(100vw-4rem))] rounded-t-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:p-0 lg:backdrop-blur-0">
          <video controls={false} autoPlay loop muted className="w-full max-h-[400px] lg:w-[480px] object-contain object-center" src={ShirtDrop3Video}>
              <source src="/path/to/your/video.webm" type="video/webm" />
              Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[min(550px,calc(100vw-4rem))] justify-center rounded-b-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:backdrop-blur-0">
        <div className="cursor-pointer">
            <a href="/shirt-drop-ada-moon-drop" className=" flex-column flex w-full items-center justify-center rounded-full bg-red py-4 px-8 text-center font-gravity-compressed text-[24px] uppercase leading-[0.75em] tracking-wide text-white">
              MINT OREMOB SHIRT ADA MOON DROP
            </a>
        </div>
      </div>
    </section>
  )
}

export default shirtDrop3Trigger
