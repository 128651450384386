import React, { useRef, useState } from "react"
import { FaPlayCircle, FaPauseCircle  } from "react-icons/fa";

type Props = {
  className?: string
  containerClassName?: string
  src: string
  type?: string
}

const VideoWithCustomControls: React.FC<Props> = ({ containerClassName, className = "w-full relative", src, type }) => {
  const audio = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <div className={containerClassName}>
        <video
            ref={audio}
            playsInline
            controls={false}
            loop
            className={className}
        >
            <source src={src} type={type || "video/mp4"} />
        </video>
        <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center group">
            {isPlaying ? <FaPauseCircle 
              className="w-[64px] h-[64px] opacity-0 group-hover:opacity-100 transition-all duration-200 cursor-pointer"
              onClick={() => {
                setIsPlaying(false);
                audio.current?.pause()
              }}
            /> : <FaPlayCircle 
              className="w-[64px] h-[64px] cursor-pointer"
              onClick={() => {
                setIsPlaying(true);
                audio.current?.play()
              }}
            />}
        </div>
    </div>
  )
}

export default VideoWithCustomControls
