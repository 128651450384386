class UTCDate {
  private readonly internalDate: Date

  /**
   * @param year
   * @param month An integer between 1 and 12 representing the month.
   * @param day An integer between 0 and 31 representing the day.
   * @param hours An integer between 0 and 23 representing the hours.
   * @param minutes An integer between 0 and 59 representing the minutes.
   * @param seconds An integer between 0 and 59 representing the seconds.
   */
  constructor(
    year: number,
    month: number,
    day: number,
    hours: number,
    minutes: number,
    seconds: number
  ) {
    this.internalDate = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, seconds)
    )
  }

  get date(): Date {
    return this.internalDate
  }

  get year(): number {
    return this.internalDate.getUTCFullYear()
  }

  get month(): string {
    return String(this.internalDate.getUTCMonth() + 1).padStart(2, "0")
  }

  get day(): string {
    return String(this.internalDate.getUTCDate()).padStart(2, "0")
  }

  get hours(): string {
    return String(this.internalDate.getUTCHours()).padStart(2, "0")
  }

  get minutes(): string {
    return String(this.internalDate.getUTCMinutes()).padStart(2, "0")
  }

  get seconds(): string {
    return String(this.internalDate.getUTCSeconds()).padStart(2, "0")
  }

  diffInHours(other: UTCDate): number {
    const msInHour = 1000 * 60 * 60

    return Math.round(
      Math.abs(other.date.getTime() - this.internalDate.getTime()) / msInHour
    )
  }
}

export default UTCDate
