import React, { CSSProperties } from "react"
import TriggerVideo from "../../videos/tiamonds/tiamonds-trigger.mp4"
import VideoWithCustomControls from "../videoWithCustomControls"

type Props = {
  className?: string
  style?: CSSProperties
}

const tiamondsTrigger: React.FC<Props> = ({ className = "", style }) => {
  return (
    <section
      className={`flex w-[100vw] flex-col items-center justify-center sm:min-h-[100vh] lg:gap-4 ${className}`}
      style={style}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        <h2 className="mb-12 w-full text-center font-gravity-compressed uppercase text-[120px] leading-[0.77] md:text-[180px] lg:mb-0 lg:w-auto lg:text-right">
          OREMOB
          <br />
          x NMKR
          <br />
          x TIAMONDS 
        </h2>
        <div className="mx-auto max-w-[min(550px,calc(100vw-4rem))] rounded-t-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:p-0 lg:backdrop-blur-0">
          <VideoWithCustomControls 
            src={TriggerVideo}
            containerClassName="w-screen lg:w-full max-w-full"
            className="max-h-[400px] object-fit mx-auto lg:mr-auto"
          />
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[min(550px,calc(100vw-4rem))] justify-center rounded-b-[10px] bg-white/50 p-4 backdrop-blur-lg lg:max-w-none lg:bg-transparent lg:backdrop-blur-0">
        <div className="cursor-pointer">
            <a href="https://tiamonds.oremob.io/" target="_blank" className=" flex-column flex w-full items-center justify-center rounded-full bg-red py-4 px-8 text-center font-gravity-compressed text-[24px] uppercase leading-[0.75em] tracking-wide text-white" rel="noreferrer">
              MINT TIAMONDS
            </a>
        </div>
      </div>
    </section>
  )
}

export default tiamondsTrigger
